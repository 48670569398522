import { BiBellPlus, BiHistory, BiHomeCircle, BiLogOut, BiPauseCircle, BiShieldPlus, BiShieldQuarter, BiUserPlus } from 'react-icons/bi';
import { AiOutlineUsergroupDelete } from 'react-icons/ai';
const ViewAdminControls = ({setModalAction}) => {
    const menuList = [
        { title: 'Send Memo', icon: <BiBellPlus />,action:"send-memo" },
        { title: 'Add Member', icon: <BiUserPlus />,action:"add-member" },
        { title: 'Delete Member', icon: <AiOutlineUsergroupDelete />,action:"delete-member" },
        { title: 'Make Admin', icon: <BiShieldPlus />,action:"make-admin" },
        { title: 'Renew Subscription', icon: <BiHistory />,action:"renew-subscription" },
        { title: 'Suspend Member', icon: <BiPauseCircle />,action:"suspend-member" },
    ]
    return <>
        <ul className='admin-controls  bg-white'>
            {menuList.map((item) => {
                return <li onClick={()=>setModalAction(item.action)}>
                    <div className='control-icon'>{item.icon}</div>
                    <div  className='control-title'>{item.title}</div>
                </li>
            })}
        </ul>

    </>
}

export default ViewAdminControls;