import { useEffect, useState } from "react";
import { ButtonsABSK, InputABSK } from "../../../system/components";

const ViewWebsite = ({ data, updateData }) => {
    const [phone, setPhone] = useState(data.phone);
    const [title_top, setTitleTop] = useState(data.title_top);
    const [title_main, setTitleMain] = useState(data.title_main);
    const [title_bottom, setTitleBottom] = useState(data.description);
    const [twitter, setTwitter] = useState(data.twitter);
    const [facebook, setFacebook] = useState(data.facebook);
    const [linkedin, setLinkedin] = useState(data.linkedin);
    useEffect(() => {
        setPhone(data.phone);
        setTitleTop(data.title_top ? data.title_top : "Welcome to my ABSK profile");
        setTitleMain(data.title_main ? data.title_main : "Association Of Behavioural Scientists Kenya, Africa.");
        setTitleBottom(data.title_bottom ? data.title_bottom : "I am a member of ABSK Africa, an association of Behavioural Scientists committed to all round development of the person")
        setTwitter(data.twitter);
        setFacebook(data.facebook);
        setLinkedin(data.linkedin);
    }, [data]);
    return <>
        <div className='content-block bg-white form-parent' style={{ position: 'relative' }}>
            <h3 className="text-left">Page Content</h3>
            <p className="text-left" style={{ color: '#ddd', marginBottom: 10 }}>Edit your page </p>
            <InputABSK.upDown placeholder={"phone number"} value={phone} setValue={setPhone} />
            <InputABSK.upDown placeholder={"title top"} value={title_top} setValue={setTitleTop} />
            <InputABSK.upDown placeholder={"main title"} value={title_main} setValue={setTitleMain} />
            <InputABSK.upDown placeholder={"sub title"} value={title_bottom} setValue={setTitleBottom} />
            <div className='hor-flex noPadding' style={{ columnGap: 15 }}>
                <InputABSK.upDown style={{color:'#ccc'}} placeholder={"Twitter"} value={twitter} setValue={setTwitter} />
                <InputABSK.upDown style={{color:'#ccc'}} placeholder={"Facebook"} value={facebook} setValue={setFacebook} />
                <InputABSK.upDown style={{color:'#ccc'}} placeholder={"Linkedin"} value={linkedin} setValue={setLinkedin} />
            </div>
            <div className="hor-flex" style={{ justifyContent: 'right' }}>
                <a onClick={()=>updateData({
                    phone: phone ? phone : false,
                    title_top,
                    title_main,
                    title_bottom,
                    twitter: twitter ? twitter : false,
                    facebook: facebook ? facebook : false,
                    linkedin: linkedin ? linkedin : false
                })}
                    className="but">Save</a></div>
            {/* <ButtonsABSK.primary text={"Update Details"} style={{ marginTop: 25 }} /> */}
        </div>
    </>
}
export default ViewWebsite;