import { MdOutlineWindow } from 'react-icons/md';
import { BiEnvelope, BiHomeCircle, BiLogOut, BiMessage, BiShieldQuarter } from 'react-icons/bi';
import { useNavigate } from "react-router";
import { Link, useLocation } from 'react-router-dom';

import '../home.css'
import { useEffect, useState } from 'react';
import { BsInbox } from 'react-icons/bs';
import { AiOutlineCalendar, AiOutlineInbox } from 'react-icons/ai';
const HomeLeftMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(0);

    const [selected, setSelected] = useState(0);
    const menuItems = [
        { icon: <MdOutlineWindow />, to: '/home/dashboard' },
        // { icon: <FiInbox />, title: 'inbox' },
        isAdmin && { icon: <BiShieldQuarter />, to: '/home/admin' },
        isAdmin && { icon: <AiOutlineInbox />, to: '/home/inbox' },
        isAdmin && { icon: <AiOutlineCalendar />, to: '/home/events' },
        { icon: <BiLogOut />, to: '/' }
    ]
    const updateLeftMenu = () => {
        console.log("here");
        setIsAdmin(true)
    }
    useEffect(() => {
        window.updateLeftMenu = updateLeftMenu;

    }, []);
    return <>
        <div className='left-menu'>
            <div className='left-logo-top'>ABSK</div>
            <ul >
                {menuItems.map((item, index) => {
                    if (!item) return;
                    return <li key={index} onClick={() => {
                        setSelected(index);
                        menuItems[index]['to'] === "/" ?(()=> {window.location = "/"})() : navigate(menuItems[index]['to']);
                    }} className={location.pathname === menuItems[index]['to'] ? "left-menu-active" : ""}>
                        {item.icon}
                    </li>
                })}
            </ul>
        </div>

    </>
}
export default HomeLeftMenu;