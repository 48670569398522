export const timeStampReadable = (dateString) => {
    const date = new Date(dateString);
    // Get the day and month names
    const options = { weekday: 'long', month: 'long' };
    const dayName = date.toLocaleString('en-US', options);
    const monthName = date.toLocaleString('en-US', { month: 'long' });
    // Get the components of the date and time
    const year = date.getFullYear();
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    // Determine the AM/PM indicator
    const amPm = hours >= 12 ? 'PM' : 'AM';
    // Adjust the hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Convert 0 to 12
    // Create the readable format
    const readableFormat = `${monthName} ${day}, ${year}`;
    console.log(readableFormat);
    return readableFormat;
}
export const timeStampReadableWithTime = (dateString) => {
    const date = new Date(dateString);
    // Get the day and month names
    const options = { weekday: 'long', month: 'long' };
    const dayName = date.toLocaleString('en-US', options);
    const monthName = date.toLocaleString('en-US', { month: 'long' });
    // Get the components of the date and time
    const year = date.getFullYear();
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    // Determine the AM/PM indicator
    const amPm = hours >= 12 ? 'PM' : 'AM';
    // Adjust the hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Convert 0 to 12
    // Create the readable format
    const readableFormat = `${monthName} ${day}, ${year} - ${hours}:${minutes}${amPm}`;
    console.log(readableFormat);
    return readableFormat;
}
export const timestampToInputFormat = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().substring(0, 10);
    return formattedDate;
}
export const generateRandomString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
export const daysToDate = (stamp) => {
    const currentDate = new Date();

    // Define the target date (year, month - 1, day)
    const targetDate = new Date(stamp); // Note: Month is zero-based (0 = January)
    targetDate.setFullYear(targetDate.getFullYear() + 1);
    // Calculate the difference in milliseconds between the current date and the target date
    const timeDifference = targetDate.getTime() - currentDate.getTime();

    // Convert the time difference to days
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysRemaining;
}