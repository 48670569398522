import { BsEnvelope, BsPhone } from 'react-icons/bs';
import './contact.scss'
import { getDatabase, ref, set } from 'firebase/database';
import { firebaseApp } from '../../system/firebase';
import { useState } from 'react';
import { generateRandomString } from '../../system/general_methodology';
const ViewContact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const db = getDatabase(firebaseApp);

    const sendMessage = async () => {
        if (email.length == 0 || phone.length == 0 || name.length == 0 || name.length == 0) {
           return alert("Please fill in the fields to proceed")
        }
        await set(ref(db, 'INBOX/' + email.replace(/\W/g, '')), {
            "email": email,
            "phone": phone,
            "name": name,
            "last_sent": Date.now(),
            "seen": false
        });
        await set(ref(db, 'INBOX/' + email.replace(/\W/g, '') + '/thread/' + generateRandomString(12)), {
            "message": message,
            "email": email,
            "phone": phone,
            "name": name,
            "sent": Date.now(),
        });
        setMessage("");
        setEmail("");
        setPhone("");
        setName("");
        window.location = "/message-sent"
    }
    return <>
        <section className="contact contact-parent" id="contact">
            <div className="container">
                <div className="heading text-center">
                    <h1 className="image-text">Contact Us</h1>

                    <p>Get in Touch - Reach Out to Us for Questions, Feedback, or Collaborations Today!</p>
                </div>
                <div className="row">
                    <div className="col-md-6 left">
                        <div className="title">
                            <h3>Get in Touch</h3>
                            <p>Use the info below to talk to us </p>
                        </div>
                        <div className="content">
                            <div className="info">
                                <BsPhone />
                                <h4 className="d-inline-block">
                                    PHONE :
                                    <br />
                                    <span>+254 721 687 093</span>
                                </h4>
                            </div>
                            <div className="info">
                                <BsEnvelope />
                                <h4 className="d-inline-block">EMAIL :
                                    <br />
                                    <span>support@abskafrica.com</span>
                                </h4>
                            </div>
                            {/* <div className="info">
                                <i className="fas fa-map-marker-alt"></i>
                                <h4 className="d-inline-block">ADDRESS :<br />
                                    <span>6743 last street , Abcd, Xyz</span>
                                </h4>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-6 right">
                        <div className="row">
                            <div className="col-sm-6">
                                <input type="text" className="form-controls" placeholder="Name" value={name} onChange={(e) => { setName(e.target.value) }} />
                            </div>
                            <div className="col-sm-6 no-left">
                                <input type="email" className="form-controls" placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                            <div className="col-sm-12">
                                <input type="phone" className="form-controls" placeholder="Phone" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea className="form-controls" rows="5" id="comment" placeholder="Message" value={message} onChange={(e) => { setMessage(e.target.value) }}></textarea>
                        </div>
                        <button className="section-btn african-but" type="submit" onClick={sendMessage}>Send</button>
                    </div>
                </div>
            </div>
        </section>
    </>
}
export default ViewContact;