import { AiOutlineEye } from 'react-icons/ai';
import { VscBook, VscEye, VscShield } from 'react-icons/vsc';
import {TbBrandDaysCounter, TbMailbox, TbUsers} from 'react-icons/tb'

const ViewAdminStats = ({members}) => {
    return <>
     
            <ul className="stats-section">
            <li className="hor-flex bg-white">
                    <div className="ver-flex">
                        <span className="title">Members</span>
                        <span className="value">{members.length}</span>
                    </div>
                    <div className='hor-flex centered'>
                        <TbUsers size={50} color="#ddd" />
                    </div>
                </li>
                <li className="hor-flex bg-white">
                    <div className="ver-flex">
                        <span className="title">Admins</span>
                        <span className="value">{members.filter((i)=>i.is_admin).length}</span>
                    </div>
                    <div className='hor-flex centered'>
                        <VscShield size={50} color="#ddd" />
                    </div>
                </li>
                <li className="hor-flex bg-white">
                    <div className="ver-flex">
                        <span className="title">page visits</span>
                        <span className="value">0</span>
                    </div>
                    <div className='hor-flex centered'>
                        <AiOutlineEye size={50} color="#ddd" />
                    </div>
                </li>
                
                <li className="hor-flex bg-white">
                    <div className="ver-flex">
                        <span className="title">website inbox</span>
                        <span className="value">0</span>
                    </div>
                    <div className='hor-flex centered'>
                        <TbMailbox size={50} color="#ddd" />
                    </div>
                </li>
            </ul>


    </>
}
export default ViewAdminStats;