import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyAcwXrRToffYR1tUr4r2sDuNqMiJp7bAvo",
    authDomain: "abskweb.firebaseapp.com",
    databaseURL: "https://abskweb-default-rtdb.firebaseio.com",
    projectId: "abskweb",
    storageBucket: "abskweb.appspot.com",
    messagingSenderId: "540231708431",
    appId: "1:540231708431:web:b128a178f746bf8fd7987a",
    measurementId: "G-1B25FXCMW8"
};

export const firebaseApp = initializeApp(firebaseConfig);