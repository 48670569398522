import { BiArrowBack } from "react-icons/bi";
import { timeStampReadable } from "../../../system/general_methodology";

const ViewMembersProfile = ({ setView, selected }) => {
    return <>
        <div className="members-profile-head" onClick={() => setView("table")}><BiArrowBack size={25} /> Back</div>
        <div className="members-body ver-flex centered">
            <div className="profile-medium" style={{ backgroundImage: `url("${selected.picture?selected.picture:"https://www.abskafrica.com/images/profile2.png"}")` }}></div>
            <div className="members-name">{selected.name}</div>
            <table className="members-details">
                <tbody>
                    <tr> <td>Url:</td><td>abskafrica.com/{selected.url_space}</td></tr>
                    <tr> <td>Email:</td><td>{selected.email}</td></tr>
                    <tr> <td>Phone:</td><td>{selected.phone}</td></tr>
                    <tr> <td>Subscription:</td><td>{timeStampReadable(selected.subscription)}</td></tr>
                    <tr> <td>Last Login:</td><td>{selected.last_login?timeStampReadable(selected.last_login):"never"}</td></tr>
                    <tr> <td>Primary Member:</td><td>{selected.is_primary?"yes":"no"}</td></tr>
                    <tr> <td>Title Top:</td><td>{selected.title_top}</td></tr>
                    <tr> <td>Title Main:</td><td>{selected.title_main}</td></tr>
                    <tr> <td>Title Bottom:</td><td>{selected.title_bottom}</td></tr>
                </tbody>
            </table>
        </div>
    </>
}

export default ViewMembersProfile;