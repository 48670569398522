import { BiTrashAlt } from "react-icons/bi";
import { ButtonsABSK, CheckboxABSK, SelectABSK } from "../../../system/components";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { getDatabase, ref, remove } from "firebase/database";
import { firebaseApp } from "../../../system/firebase";

const ViewAdminActionDelete = ({ members, setModalAction }) => {
    const [user, setUser] = useState(members[0]);
    const [sure, setSure] = useState(false);
    const [loading, setLoading] = useState(false);

    const db = getDatabase(firebaseApp);

    const deleteTheMember = async () => {
        if(!sure)return;
        setLoading(true);
        console.log(user);
        await remove(ref(db, 'MEMBERS/' + user.uid));
        await remove(ref(db, 'MEMOS/' + user.uid));
       
        setTimeout(()=>{
            setModalAction(false);
            window.location.reload();
        },2000);

    }
    if (loading) {
        return <div className="modal-inner bg-white ver-flex centered">
            <div className="lds-spinner spinner-orange" style={{ margin: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3>Deleting the user</h3>
        </div>
    }
    return <div className="modal-inner bg-white ver-flex centered">
        <div className="modal-close" onClick={() => setModalAction(false)}>
            <AiOutlineClose />
        </div>
        <img src={require('../../../assets/illustration/trash.svg').default} width={270} style={{ marginBottom: 20 }} />
        <SelectABSK.Transparent items={members} style={{ width: 300, marginTop: 20 }} value={user} setValue={setUser} />
        <CheckboxABSK.primary text={"I am sure"} value={sure} setValue={setSure}/>
        <ButtonsABSK.primary onClick={deleteTheMember} text={"Delete Member"} style={{ width: 300, height: 50, fontSize: 15, marginTop: 15 }} />
    </div>
}
export default ViewAdminActionDelete;