import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { firebaseApp } from "../../system/firebase";
import { timeStampReadable, timeStampReadableWithTime, timestampToInputFormat } from "../../system/general_methodology";

const SubrouteInbox = () => {
    const [inbox, setInbox] = useState();
    useEffect(() => {
        const database = getDatabase(firebaseApp);
        const inboxRef = ref(database, 'INBOX');
        onValue(inboxRef, (snapshot) => {
            var newMessages = [];
            snapshot.forEach((thread) => {
                newMessages.push(thread.val());
                // var i = 0;


                // console.log("snap",snap.val()[0]);

            });
            console.log("newMessages", newMessages);
            setInbox(newMessages);
        });

    }, []);
    return <div className='main-content'>
        <div className='main-header'>
            <h1 className='noPadding main-heading'><span className='title-thin'>Admin</span> Panel </h1>
            <div className='main-header-right'>
                <div>ABSK ADMIN</div>
                <div className='profile-small'></div>
            </div>
        </div>
        <div className='main-content-inner messages-parent'>
            <div className="messages-list">
                {inbox?.map((item, index) => {
                    console.log("item", item);
                    const mess = [];
                    for (const [key, value] of Object.entries(item.thread)) {
                        console.log(`Key: ${key}, Value: ${value}`);
                        mess.push(value)
                    }
                    // item.thread.forEach((snap) => {
                    //     console.log("snap", snap);
                    //     // if (i === 0) {

                    //     //     i++;
                    //     // } else {
                    //     //     return;
                    //     // }
                    // });
                    return <div className="message-wrap">
                        <div className="message" key={index}>
                            <div>{item.name}</div>
                            <div>{item.email}</div>
                            <div>{item.phone}</div>
                            <div className="time">{timeStampReadable(item.last_sent)}</div>
                        </div>
                        <div className="thread">
                            {mess.map((data) => {
                                return <div>
                                    <div className="mess-time">{timeStampReadableWithTime(data.sent)}</div>
                                    <div>{data.message}</div>
                                </div>
                            })}
                        </div>
                    </div>
                })}
            </div>

        </div>

    </div>
}
export default SubrouteInbox;