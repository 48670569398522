import { BiTrashAlt } from "react-icons/bi";
import { ButtonsABSK, CheckboxABSK, InputABSK, SelectABSK, TextAreaAbsk } from "../../../system/components";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { getDatabase, ref, set } from "firebase/database";
import { useState } from "react";
import { firebaseApp } from "../../../system/firebase";

const ViewAdminActionAddMember = ({ members, setModalAction }) => {
    const db = getDatabase(firebaseApp);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(false);
    const [password, setPassword] = useState(false);
    const [urlSpace, setUrlSpace] = useState(false);
    const [email, setEmail] = useState(false);
    const [phone, setPhone] = useState(false);

    const addMember = () => {
        if (!(name && password && urlSpace && email && phone)) return alert("Fill in all required fields")
        setLoading(true);
        const url = 'https://us-central1-abskweb.cloudfunctions.net/profile';
        const data = {
            email: email,
            password: password
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(async data => {
                console.log('Response:', data);
                await set(ref(db, 'MEMBERS/' + data.user.uid), {
                    "email": email,
                    "name": name,
                    "url_space": urlSpace,
                    "password": password,
                    "phone": phone,
                    "subscription": Date.now()
                });
                setModalAction(false);
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
                alert(error);
                setLoading(false);
            });
    }
    if (loading) {
        return <div className="modal-inner bg-white ver-flex centered">
            <div className="lds-spinner spinner-orange" style={{ margin: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3>Creating User Profile</h3>
        </div>
    }
    return <div className="modal-inner bg-white ver-flex centered">
        <div className="modal-close" onClick={() => setModalAction(false)}>
            <AiOutlineClose />
        </div>
        <h3 style={{ marginBottom: 15 }}>Add <span className="primary-color">New</span> Member</h3>
        <InputABSK.upDown placeholder={"Full Name"} style={{ width: 350 }} value={name} setValue={setName}/>
        <div className='hor-flex noPadding' style={{ width: 350, columnGap: 15 }}>
            <InputABSK.upDown placeholder={"url space"}  value={urlSpace} setValue={setUrlSpace}/>
            <InputABSK.upDown placeholder={"phone"}  value={phone} setValue={setPhone}/>
        </div>
        <div className='hor-flex noPadding' style={{ width: 350, columnGap: 15 }}>
            <InputABSK.upDown placeholder={"email"}  value={email} setValue={setEmail}/>
            <InputABSK.upDown placeholder={"password"}  value={password} setValue={setPassword}/>
        </div>
        <ButtonsABSK.primary onClick={addMember} text={"Create Account"} style={{ width: 350, height: 50, fontSize: 15, marginTop: 0 }} />
    </div>
}
export default ViewAdminActionAddMember;