import { BiTrashAlt } from "react-icons/bi";
import { ButtonsABSK, CheckboxABSK, SelectABSK } from "../../../system/components";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { getDatabase, ref, remove, set } from "firebase/database";
import { firebaseApp } from "../../../system/firebase";

const ViewAdminActionSuspend = ({ members, setModalAction }) => {
    const [user, setUser] = useState(members[0]);
    const [sure, setSure] = useState(false);
    const [loading, setLoading] = useState(false);

    const db = getDatabase(firebaseApp);

    const makeAdminMember = async () => {
        if(!sure)return;
        setLoading(true);
        console.log(user);
        await set(ref(db, 'MEMBERS/' + user.uid+'/is_suspended'),true);
        setModalAction(false);
        window.location.reload();

    }
    if (loading) {
        return <div className="modal-inner bg-white ver-flex centered">
            <div className="lds-spinner spinner-orange" style={{ margin: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3>Renewing</h3>
        </div>
    }
    return <div className="modal-inner bg-white ver-flex centered">
        <div className="modal-close" onClick={() => setModalAction(false)}>
            <AiOutlineClose />
        </div>
        <img src={require('../../../assets/illustration/ban.svg').default} width={270} height={150} style={{ marginBottom: 20 }} />
        <SelectABSK.Transparent items={members} style={{ width: 300, marginTop: 20 }} value={user} setValue={setUser} />
        <CheckboxABSK.primary text={"I am sure"} value={sure} setValue={setSure}/>
        <ButtonsABSK.primary onClick={makeAdminMember} text={"Suspend Account"} style={{ width: 300, height: 50, fontSize: 15, marginTop: 15 }} />
    </div>
}
export default ViewAdminActionSuspend;