import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { firebaseApp } from "../../system/firebase";
import { useNavigate } from "react-router";

const ViewSignin = () => {
    const auth = getAuth(firebaseApp);
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const login = () => {
        setLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log("user", user);
                navigate("/home/dashboard");
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                alert(errorMessage)
                setLoading(false);

            });
    }
    return <section className="modal fade" id="modal-form" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <div className="modal-content modal-popup">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="modal-title">
                                    <h2>ABSK Africa</h2>
                                </div>
                                {/* NAV TABS */}
                                <ul className="nav nav-tabs" role="tablist">
                                    <li><a href="#sign_in" aria-controls="sign_in" role="tab" data-toggle="tab">Sign In</a></li>
                                </ul>
                                {/* TAB PANES */}
                                <div className="tab-content">
                                    <div role="tabpanel" className="tab-pane fade in active" id="sign_in">
                                        {loading
                                            ?
                                            <>
                                                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                                <h4>loging you in</h4>

                                            </>
                                            :
                                            <>
                                                <div className="form" action="#" method="post">
                                                    <input type="email" className="form-control" name="email" placeholder="Email" required onChange={(e) => setEmail(e.target.value)} />
                                                    <input type="password" className="form-control" name="password" placeholder="Password" required onChange={(e) => setPassword(e.target.value)} />
                                                    <input type="submit" className="form-control" name="submit" defaultValue="Login" onClick={login} />
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
export default ViewSignin;