import { AiOutlineEye } from 'react-icons/ai';
import { VscBook, VscEye } from 'react-icons/vsc';
import {TbBrandDaysCounter, TbMailbox} from 'react-icons/tb'
import { daysToDate } from '../../../system/general_methodology';

const ViewStats = ({data}) => {
    return <>
     
            <ul className="stats-section">
                <li className="hor-flex bg-white">
                    <div className="ver-flex">
                        <span className="title">page visits</span>
                        <span className="value">{data.page_views?data.page_views:0}</span>
                    </div>
                    <div className='hor-flex centered'>
                        <AiOutlineEye size={50} color="#ddd" />
                    </div>
                </li>
                {/* <li className="hor-flex bg-white">
                    <div className="ver-flex">
                        <span className="title">Publications</span>
                        <span className="value">0</span>
                    </div>
                    <div className='hor-flex centered'>
                        <VscBook size={50} color="#ddd" />
                    </div>
                </li> */}
                <li className="hor-flex bg-white">
                    <div className="ver-flex">
                        <span className="title">next renewal</span>
                        <span className="value">{data.subscription?daysToDate(data.subscription):""}<span style={{fontSize:10,marginTop:5,color:'#ccc'}}>days</span></span>
                    </div>
                    <div className='hor-flex centered'>
                        <TbBrandDaysCounter size={50} color="#ddd" />
                    </div>
                </li>
                {/* <li className="hor-flex bg-white">
                    <div className="ver-flex">
                        <span className="title">website inbox</span>
                        <span className="value">0</span>
                    </div>
                    <div className='hor-flex centered'>
                        <TbMailbox size={50} color="#ddd" />
                    </div>
                </li> */}
            </ul>


    </>
}
export default ViewStats;