import { useEffect, useState } from "react";
import { InputABSK } from "../../../system/components";
import { AiFillCamera, AiOutlineCamera, AiOutlineUpload, AiOutlineUsergroupDelete } from 'react-icons/ai';
import imageCompression from 'browser-image-compression';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { firebaseApp } from "../../../system/firebase";

const ViewProfile = ({ data, uid, updateData }) => {
    const storage = getStorage(firebaseApp);

    const [picture, setPicture] = useState(data.picture);
    const [urlSpace, setUrlSpace] = useState(data.url_space);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(data.titleTop);
    useEffect(() => {
        setPicture(data.picture);
        setUrlSpace(data.url_space);
        setEmail(data.email);
    }, [data]);

    const uploadDP = async (event) => {
        console.log(0)
        setLoading(true);
        var imageFile = event.target.files[0];
        var localLink = "";
        var reader = new FileReader();
        var url = reader.readAsDataURL(imageFile);
        let preview;
        reader.onloadend = function (e) {
            console.log(1);
            preview = [reader.result];

        }.bind(this);
        //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        var extension = "";
        var filename = imageFile.name.toLowerCase();
        if (filename.includes("png")) {
            extension = ".png"
        }
        if (filename.includes("jpg")) {
            extension = ".jpg"
        }
        const thumb_options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 250,
            useWebWorker: true,
            fileType: "image/png"
        }
        const main_options = {
            maxSizeMB: 0.4,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: "image/jpeg"
        }
        const compressedThumb = await imageCompression(imageFile, thumb_options);
        console.log(`compressed thumb size ${compressedThumb.size / 1024 / 1024} MB`);
        const compressedMain = await imageCompression(imageFile, main_options);
        console.log(`compressed Main size ${compressedThumb.size / 1024 / 1024} MB`);

        const thumbRef = ref(storage, `profile_pics/${uid}_thumb.png`);
        const mainRef = ref(storage, `profile_pics/${uid}_main.png`);
        // 'file' comes from the Blob or File API
        uploadBytes(thumbRef, compressedThumb).then((snapshot) => {
            console.log('Uploaded thumb file!', snapshot);
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                console.log('Thumb File available at', downloadURL);
                updateData({
                    picture: downloadURL
                })
            });
            uploadBytes(mainRef, compressedMain).then((snapshot2) => {
                console.log('Uploaded  main file!', snapshot2);
                getDownloadURL(snapshot2.ref).then((downloadURL) => {
                    console.log('Main File available at', downloadURL);
                    updateData({
                        picture_lg: downloadURL
                    });
                    setPicture(preview)
                    setLoading(false);
                });
            });
        });


    }


    return <div className=" bg-white" style={{ padding: 50, columnGap: 40, borderRadius: 20 }}>

        <div className="hor-flex centered"  style={{ columnGap: 40}}>
            <div className="upload-profile-picture centered vr-flex hor-flex" style={{ backgroundImage: `url("${picture ? picture : "https://www.abskafrica.com/images/profile2.png"}")` }}>
                {loading
                    ?
                    <>
                        <img src={require("../../../assets/anim/load.gif")} width={70} />
                    </>
                    :
                    <>
                        <label>
                            <div className="centered vr-flex hor-flex"><AiOutlineCamera /></div>
                            <input type="file" style={{ display: 'none' }} onChange={(event) => { uploadDP(event) }} accept="image/*" />
                        </label>
                    </>
                }

            </div>
            <div style={{ marginTop: -50 }}>
                <h3 className="text-left" style={{ marginBottom: 20 }}>Profile Info</h3>
                <a className="text-left" href={`https://abskafrica.com/${urlSpace}`} target="_blank">https://abskafrica.com/{urlSpace}</a>
                <div className="text-left" style={{ color: '#ccc', marginTop: 10 }}>{email}</div>
            </div>
        </div>
        <InputABSK.upDown placeholder={"Preferred Title (eg. Bunji)"} value={data.title} setValue={(value)=>{
            updateData({
                title: value
            })
        }}/>
        <InputABSK.upDownTextArea placeholder={"Your Biodata"} value={data.biodata} setValue={(value)=>{
            updateData({
                biodata: value
            })
        }}/>


        {/* <InputABSK.bottomLine placeholder={"username"}/> */}



    </div>
}
export default ViewProfile;