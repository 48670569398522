import { useState } from "react"
import { timestampToInputFormat } from "./general_methodology"

export const InputABSK = {
    bottomLine: ({ placeholder, value, setValue }) => {
        return <div className="bottom-line">
            <input placeholder={placeholder} value={value ? value : ""} onChange={(e) => { setValue && setValue(e.target.value) }} />
        </div>
    },
    sideBySide: ({ placeholder, value, setValue }) => {
        return <div className="side-by-side">
            <div className="side-title">{placeholder}:</div>
            <input placeholder={"Enter " + placeholder} value={value ? value : ""} onChange={(e) => { setValue && setValue(e.target.value) }} />
        </div>
    },
    upDown: ({ placeholder, value, setValue, style }) => {
        return <div className="up-down" style={style ? style : {}}>
            <div className="up-title">{placeholder}:</div>
            <input placeholder={"Enter " + placeholder} value={value ? value : ""} onChange={(e) => { setValue && setValue(e.target.value) }} />
        </div>
    },
    upDownTextArea: ({ placeholder, value, setValue, style }) => {
        return <div className="up-down" style={style ? style : {}}>
            <div className="up-title">{placeholder}:</div>
            <textarea placeholder={"Enter " + placeholder} value={value ? value : ""} onChange={(e) => { setValue && setValue(e.target.value) }} />
        </div>
    }
}
export const ButtonsABSK = {
    primary: ({ text, style, onClick }) => {
        return <a style={{ ...style ? style : {} }} className="but" onClick={onClick ? onClick : () => { }}>{text}</a>
    }
}
export const SelectABSK = {
    Transparent: ({ items, style, value, setValue }) => {
        const [val, setVal] = useState(value ? value.name : false);
        return <div className="select-transparent" style={style ? style : {}}>
            <select value={val} onChange={(e) => { if (setValue) { console.log(e.target.value); setVal(e.target.value); setValue(JSON.parse(e.target.value)) } }}>
                {items.map((item) => {
                    return <option value={JSON.stringify(item)}>{item.name}</option>
                })}
            </select>
        </div>
    },
    upDown: ({ placeholder, items, style }) => {
        return <div className="up-down" style={style ? style : {}}>
            <div className="up-title">{placeholder}:</div>
            <select>
                {items.map((item) => {
                    return <option>{item.name}</option>
                })}
            </select>
        </div>
    }
}
export const CheckboxABSK = {
    primary: ({ text, style, value, setValue }) => {
        return <div className="checkbox-primary">
            <input type="checkbox" id="check" name="vehicle3" value="Boat" checked={value} onChange={(e) => setValue(e.target.checked)} />
            <label for="check">{text}</label>
        </div>
    }
}
export const DatePickersABSK = {
    
    Primary: ({ text, style, value, setValue }) => {
        const [val, setVal] = useState(value ? value : false);

        return <div className="datepick-primary centered">
            <label for="birthday">{text}:</label>

            <input type="date" id="birthday" name="birthday" value={timestampToInputFormat(val)} onChange={(e) => { if (setValue) { console.log(val); setVal(e.target.value); setValue( Date.parse(e.target.value)) } }}/>
        </div>
    }
}
export const TextAreaAbsk = {
    primary: ({ placeholder, value, setValue }) => {
        return <div className="text-area-ptimary">
            <textarea placeholder={placeholder} value={value ? value : ""} onChange={(e) => { setValue && setValue(e.target.value) }} />
        </div>
    }
}
