import './App.scss';
import SubRouteAdmin from './screens/admin/admin';
import SubrouteDashboard from './screens/home/components/main_content';
import RouteHome from './screens/home/home';
import SubrouteInbox from './screens/admin/inbox';
import RouteIndex from './screens/index/index';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RouteMessageSent from './screens/sent/sent';
import { RouteWebinars } from './screens/webinars/webinars';
import SubRouteWebinarsDetails from './screens/webinars/webinars-details';
import RouteWebinarsDetails from './screens/webinars/webinars-details';
import SubrouteEvents from './screens/admin/events';
import RouteWebinarsUnsubscribe from './screens/webinars/webinars-unsubscribe';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RouteIndex />,
  },
  {
    path: "/index",
    element: <RouteIndex />,
  },
  {
    path: "/home",
    element: <RouteHome />,
    children: [
      {
        path: "dashboard",
        element: <SubrouteDashboard />,
      },
      {
        path: "admin",
        element: <SubRouteAdmin />,
      },
      {
        path: "inbox",
        element: <SubrouteInbox/>,
      },
      {
        path: "events",
        element: <SubrouteEvents/>,
      }
    ],
  },
  {
    path: "/message-sent",
    element: <RouteMessageSent />,
  },
  {
    path: "/webinars",
    element: <RouteWebinars />,
  },
  {
    path: "/webinar-registration",
    element: <RouteWebinarsDetails />,
  },
  {
    path: "/webinar-unsubscribe",
    element: <RouteWebinarsUnsubscribe />,
  },
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
