import { BiTrashAlt } from "react-icons/bi";
import { ButtonsABSK, CheckboxABSK, SelectABSK, TextAreaAbsk } from "../../../system/components";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { firebaseApp } from "../../../system/firebase";
import { getDatabase, push, ref, set, update } from "firebase/database";
import { useState } from "react";
import { generateRandomString } from "../../../system/general_methodology";

const ViewAdminActionMemo = ({ members, setModalAction }) => {
    const db = getDatabase(firebaseApp);
    const [recepient, setRecepient] = useState({ name: "EVERYONE ON ABSK", uid: "everybody" });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const memoCode = generateRandomString(12);
    const sendTheMemo = async () => {
        console.log(recepient.uid);
        if (!message) return alert("Enter message first!");
        setLoading(true);
        if (recepient.uid === "everybody") {
            const fanout = {}
            members.map((item) => {
                console.log(item);
                var premem = {}
                var mem = {
                    "message": message,
                    "sent": Date.now(),
                    "seen": false
                }
                premem[memoCode] = mem;
                fanout[item.uid] = premem;
            });
            console.log(fanout);
            await update(ref(db, 'MEMOS/'), fanout);
            setModalAction(false);
        } else {
            await set(ref(db, 'MEMOS/' + recepient.uid + '/' + memoCode), {
                "message": message,
                "sent": Date.now(),
                "seen": false
            });
            setModalAction(false);
        }

    }
    if (loading) {
        return <div className="modal-inner bg-white ver-flex centered">
            <div className="lds-spinner spinner-orange" style={{ margin: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3>Sending the memo</h3>
        </div>
    }
    return <div className="modal-inner bg-white ver-flex centered">
        <div className="modal-close" onClick={() => setModalAction(false)}>
            <AiOutlineClose />
        </div>
        <h3 style={{ marginBottom: 15 }}>Write <span className="primary-color">New</span> Memo</h3>
        <TextAreaAbsk.primary placeholder={"Type the message here"} value={message} setValue={setMessage} />
        <SelectABSK.Transparent items={[{ name: "EVERYONE ON ABSK", uid: "everybody" }, ...members]} style={{ width: 350, marginTop: 20 }} value={recepient} setValue={setRecepient} />
        <ButtonsABSK.primary onClick={sendTheMemo} text={"Send Message"} style={{ width: 350, height: 50, fontSize: 15, marginTop: 15 }} />
    </div>
}
export default ViewAdminActionMemo;