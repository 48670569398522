import { FiBell, FiHome, FiInbox, FiLogOut } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import ViewProfile from './view_profile';
import ViewWebsite from './view_website';
import ViewStats from './stats';
import { firebaseApp } from "../../../system/firebase";
import { getDatabase, onValue, ref, update } from "firebase/database";
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { ViewMemos } from './memos';


const SubrouteDashboard = () => {
    const database = getDatabase(firebaseApp);
    const auth = getAuth(firebaseApp);

    const [uid, setUid] = useState({});
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({});

    useEffect(() => {
        let backdrop = document.getElementsByClassName("modal-backdrop");
        if (backdrop) backdrop = backdrop[0];
        backdrop && backdrop.remove()

        // if(!auth.currentUser) return console.log("detes")
        // console.log(auth.currentUser.uid);

        onAuthStateChanged(auth, (user) => {
            if (user) {

                const uid = user.uid;
                console.log(uid);
                const starCountRef = ref(database, 'MEMBERS/' + uid);
                onValue(starCountRef, (snapshot) => {
                    setUid(uid);
                    setLoading(false);
                    const data = snapshot.val();
                    setData(data);
                    console.log(data);
                    if (data.is_admin) {
                        window.updateLeftMenu();
                    }
                });
            } else {
                // User is signed out
                // ...
                window.location = "/"
            }

        });
    }, []);
    const updateData = async (newData) => {
        setLoading(true);
        console.log(newData, uid);
        await update(ref(database, 'MEMBERS/' + uid), newData);
        setLoading(false);

    }

    return <>
        <div className='main-content'>
            <div className='main-header'>
                <h1 className='noPadding main-heading'><span className='title-thin'>Welcome, Bunji </span><span className='golden'>{data.name && data.name.split(" ")[0]}</span></h1>
                <div className='main-header-right'>
                    <ViewMemos uid={uid}/>
                    <div className='profile-small' style={{ backgroundImage: `url("${data.picture ? data.picture : "https://www.abskafrica.com/images/profile2.png"}")` }}></div>
                </div>
            </div>
            <div className='main-content-inner'>
                <div className='content-block'>
                    <ViewStats data={data} />
                    <ViewProfile data={data} updateData={updateData} uid={uid} />
                </div>
                <ViewWebsite data={data} updateData={updateData} />
                {/* 
                */}
            </div>
        </div>
        {loading && <>
            <div className="modal-bg">
                <div className="">
                    <div className="lds-spinner spinner-orange" style={{ margin: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <h3>loading</h3>
                </div>
            </div>
        </>}
    </>
}
export default SubrouteDashboard;