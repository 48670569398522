import { FiBell, FiHome, FiInbox, FiLogOut } from 'react-icons/fi';
import ViewAdminControls from './views/controls'
import ViewAdminStats from "./views/stats";
import ViewMembersTable from './views/table';
import { useState } from 'react';
import ViewMembersProfile from './views/profile';
import ViewAdminActionModal from './action_modal';


function SubRouteAdmin() {
    const [view, setView] = useState("table");
    const [selected, setSelected] = useState();
    const [members, setMembers] = useState([]);
    const [modalAction, setModalAction] = useState();
    return (
        <>
            <div className='main-content'>
                <div className='main-header'>
                    <h1 className='noPadding main-heading'><span className='title-thin'>Admin</span> Panel </h1>
                    <div className='main-header-right'>
                        <div>ABSK ADMIN</div>
                        <div className='profile-small'></div>
                    </div>
                </div>
                <div className='main-content-inner'>
                    <div className='content-block'>
                        <ViewAdminControls setModalAction={setModalAction}/>
                        <ViewAdminStats members={members}/>

                    </div>
                    <div className='content-block bg-white centered data-table-parent'>
                        {view === "table" && <ViewMembersTable setView={setView} setSelected={setSelected} setMembers={setMembers}/>}
                        {view === "profile" && <ViewMembersProfile setView={setView} selected={selected}/>}

                    </div>

                    {/* 
                */}
                {modalAction&&<ViewAdminActionModal members={members} modalAction={modalAction}  setModalAction={setModalAction}/>}
                </div>
            </div>
        </>
    );
}

export default SubRouteAdmin;