import { useEffect, useState } from "react"
import { firebaseApp } from "../../system/firebase";
import { getDatabase, onValue, ref } from "firebase/database";

const distributionList = [
    /** first 6 skipped */
    [4, 3, 0],//7
    [5, 2, 1],//8
    [5, 4, 0],//9
    [5, 4, 1],//10
    [5, 6, 0],//11
    [5, 4, 3],//12
    [6, 5, 2],//13
    [5, 6, 3],//14
    [6, 5, 4],//15
    [5, 6, 5],//16
    [6, 5, 6],//17
]
export const IndexPeople = () => {
    const database = getDatabase(firebaseApp);
    const [people, setPeople] = useState([]);
    const [list1, setList1] = useState([]);
    const [list2, setList2] = useState([]);
    const [list3, setList3] = useState([]);
    useEffect(() => {
        const starCountRef = ref(database, 'MEMBERS');
        onValue(starCountRef, (snapshot) => {
            var newPeople = [];
            snapshot.forEach((snap) => {
                newPeople.push(snap.val());
            });
            if (newPeople.length > 6) {
                const distribution = distributionList[newPeople.length - 7];
                console.log("dist",distribution);
                setList1(newPeople.slice(0, distribution[0]))
                setList2(newPeople.slice(distribution[0],( distribution[0] + distribution[1])));
                console.log(distribution[2]===0?0:distribution[2]*-1);
                if(distribution[2]!==0){
                    setList3(newPeople.slice(distribution[2]*-1))
                }
                //
            } else {
                setList1(newPeople);
            }
        });
    }, []);
    const _renderItem = (item,index) => {
        // return <div className="hexagon-wrap">test</div>
        return <a key={item.url_space} target="_blank" href={`https://abskafrica.com/${item.url_space}`} className="hexagon-wrap"><div className="hexagon" style={{ backgroundImage: `url("${item.picture?item.picture:"https://www.abskafrica.com/images/profile2.png"}")` }}>
            <div className="hex-content">
                <div className="username">Bunji {item.name.split(" ")[0]}</div>
                <div className="view-more">more info</div>
            </div>
        </div></a >
    }
    return <>
        <div className="hex-row">
            {list1.map((item) => {
                return _renderItem(item)
            })}
        </div>
        <div className="hex-row">
            {list2.map((item) => {
                return _renderItem(item)
            })}
        </div>
        <div className="hex-row">
            {list3.map((item) => {
                return _renderItem(item)
            })}
        </div>
    </>
}

