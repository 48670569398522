import ViewAdminActionRenew from "./modal_views/renew_subscription";
import ViewAdminActionSuspend from "./modal_views/suspend_user";
import ViewAdminActionAdmin from "./modal_views/make_admin";
import ViewAdminActionMemo from "./modal_views/send_memo";
import ViewAdminActionAddMember from "./modal_views/add_member";
import ViewAdminActionDelete from "./modal_views/delete_user";

const ViewAdminActionModal = ({ members,modalAction, setModalAction }) => {
    return <div className="modal-bg">
        {modalAction==="renew-subscription"&&<ViewAdminActionRenew members={members} setModalAction={setModalAction}/>}
        {modalAction==="suspend-member"&&<ViewAdminActionSuspend members={members} setModalAction={setModalAction}/>}
        {modalAction==="make-admin"&& <ViewAdminActionAdmin members={members} setModalAction={setModalAction}/>}
        {modalAction==="delete-member"&& <ViewAdminActionDelete members={members} setModalAction={setModalAction}/>}
        {modalAction==="send-memo"&& <ViewAdminActionMemo members={members} setModalAction={setModalAction}/>}
        {modalAction==="add-member"&&<ViewAdminActionAddMember members={members} setModalAction={setModalAction}/>} 

    </div>
}
export default ViewAdminActionModal;