import HomeLeftMenu from "./components/left_menu";
import { Outlet } from "react-router-dom";

const RouteHome = () => {
    return <>
        <section className="home-parent noPadding">
            <HomeLeftMenu />
            <Outlet/>
        </section>
    </>
}
export default RouteHome;