import { useEffect, useState } from "react"
import { FiBell } from "react-icons/fi"
import { firebaseApp } from "../../../system/firebase";
import { getDatabase, onValue, ref, update } from "firebase/database";

export const ViewMemos = ({ uid }) => {
    const database = getDatabase(firebaseApp);
    const [memos, setMemos] = useState([]);
    const [memosOpen, setMemosOpen] = useState(false);

    useEffect(() => {
        console.log(uid);
        if (typeof uid === 'string' || uid instanceof String) {
            const starCountRef = ref(database, 'MEMOS/' + uid);
            onValue(starCountRef, (snapshot) => {
                const memoz = [];
                snapshot.forEach((snap) => {
                    const data = snap.val();
                    data['uid'] = snap.key;
                    memoz.push(data);
                });
                setMemos(memoz);
                console.log(".................", memoz);
            });
        }

    }, [uid]);
    const openNotification = async () => {
        setMemosOpen(!memosOpen);
        const fanout = {}
        memos.map((item) => {
            console.log(item);
            var mem = {
                ...item,
                "seen": true
            }
            fanout[item.uid] = mem;
        });
        console.log(fanout);
        await update(ref(database, 'MEMOS/' + uid), fanout);
        //setModalAction(false);
    }
    return <div className="expandable-notification" onClick={openNotification}>
        <FiBell />
        {
            (memos.filter((n) => !n.seen).length > 0 && !memosOpen)
            &&
            <span>{memos.filter((n) => !n.seen).length}</span>
        }
        {memosOpen
            &&
            <>
                <ul className="notifications-list bg-white">
                    {memos.map((item, index) => {
                        return <li key={index}>
                            <div>{item.message}</div>
                        </li>
                    })}


                </ul>
            </>}

    </div>
}