import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { BiLink, BiLinkAlt, BiLinkExternal, BiRadio, BiShieldQuarter, BiStar, BiUser, BiWorld } from "react-icons/bi";
import { firebaseApp } from "../../../system/firebase";
import { getDatabase, onValue, ref } from "firebase/database";
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { timeStampReadable } from "../../../system/general_methodology";

const customStyles = {
    rows: {
        style: {
            minHeight: '50px', // override the row height
        },
    },
    headCells: {
        style: {
            fontWeight: '200',
            fontSize: 15,
            color: '#aaa'
        },
    },
    cells: {
        style: {
            fontSize: 13,
            textTransform: "uppercase",
        },
    },
};

function ViewMembersTable({ setView, setSelected,setMembers }) {
    const columns = [
        {
            name: 'Full Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Url',
            selector: row => <>{row.url_space}{row.is_admin&&<BiShieldQuarter title="is admin" color="#ff8500" style={{marginLeft:5}}/>}</>,
            sortable: true,
        },
        // {
        //     name: 'Email',
        //     selector: row => row.email,
        //     sortable: true,
        // },
        {
            name: 'Subscription',
            selector: row => timeStampReadable(row.subscription),
            sortable: true,
        },
        {
            name: 'Actions',
            selector: row => <div className="hor-flex actions-parent"><div onClick={()=>{
                setView("profile");
                setSelected(row);
            }}><BiUser /> </div><a target="_blank" href={'https://abskafrica.com/' + row.url_space}><BiLinkExternal /></a> </div>,
            sortable: true,
        },
    ];
    const database = getDatabase(firebaseApp);
    const auth = getAuth(firebaseApp);

    const [data, setData] = useState([]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                console.log(uid);
                var members = [];
                const starCountRef = ref(database, 'MEMBERS/');
                onValue(starCountRef, (snapshot) => {
                    snapshot.forEach((snap) => {
                        const data = snap.val();
                        data["uid"]=snap.key;
                        members.push(data);
                    });
                    setData(members);
                    setMembers(members);
                    console.log(data);
                });
            } else {
                // User is signed out
                // ...
            }
        });

    }, []);

    return (
        <>
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                pagination
                highlightOnHover
            />
        </>
    );
}

export default ViewMembersTable;