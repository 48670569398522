import { FaEnvelopeOpen, FaFacebook, FaFacebookMessenger, FaFacebookSquare, FaInstagram, FaLinkedin, FaMapPin, FaPhone, FaPlay, FaRegEnvelopeOpen, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import './TopBar.scss'
export const TopBar=()=>{
    return <div className="topbar">
    <div className="topbar-left">
        <i><FaRegEnvelopeOpen /></i> <a href="mailto:support@abskafrica.com">support@abskafrica.com</a>
        <i className="top-bar-border-left"><FaPhone /></i> +254 721 687 093
        <i className="top-bar-border-left"><FaMapPin /></i> Nairobi, Kenya
    </div>
    <div className="topbar-right">
        <a href="https://www.youtube.com/@abskafrica7951" className="facebook"><FaYoutube /></a>
        <a href="https://twitter.com/abskafrica" className="twitter"><FaTwitter /></a>
        <a href="#" className="instagram"><FaInstagram /></a>
        <a href="#" className="whatsapp"><FaWhatsapp /></a>
        <a href="https://www.facebook.com/abskafrica" className="facebook"><FaFacebook /></a>
        {/* <a href="#" className="linkedin"><FaLinkedin /></a> */}
    </div>
</div>
}