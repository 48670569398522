import { BiTrashAlt } from "react-icons/bi";
import { ButtonsABSK, CheckboxABSK, DatePickersABSK, SelectABSK } from "../../../system/components";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { getDatabase, ref, remove, set } from "firebase/database";
import { firebaseApp } from "../../../system/firebase";

const ViewAdminActionRenew = ({ members, setModalAction }) => {
    const [user, setUser] = useState(members[0]);
    const [date, setDate] = useState(Date.now());
    const [loading, setLoading] = useState(false);

    const db = getDatabase(firebaseApp);

    const renewSubscription = async () => {
        console.log(date);
        if(!date)return;
        setLoading(true);
        console.log(user);
        await set(ref(db, 'MEMBERS/' + user.uid+'/subscription'),date);
        setModalAction(false);
        window.location.reload();

    }
    if (loading) {
        return <div className="modal-inner bg-white ver-flex centered">
            <div className="lds-spinner spinner-orange" style={{ margin: 0 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h3>Renewing Subscription</h3>
        </div>
    }
    return <div className="modal-inner bg-white ver-flex centered">
        <div className="modal-close" onClick={() => setModalAction(false)}>
            <AiOutlineClose />
        </div>
        <img src={require('../../../assets/illustration/upgrade.svg').default} width={270} height={150} style={{ marginBottom: 20 }} />
        <SelectABSK.Transparent items={members} style={{ width: 300, marginTop: 20 }} value={user} setValue={setUser} />
        <DatePickersABSK.Primary text={"Subscription Date"}  value={date} setValue={setDate} />
        <ButtonsABSK.primary onClick={renewSubscription} text={"Renew Subscription"} style={{ width: 300, height: 50, fontSize: 15, marginTop: 15 }} />
    </div>
}
export default ViewAdminActionRenew;