import { get, getDatabase, onValue, orderByChild, query, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { firebaseApp } from "../../system/firebase";
import { timeStampReadable, timeStampReadableWithTime, timestampToInputFormat } from "../../system/general_methodology";
import { FiLink } from 'react-icons/fi';

const SubrouteEvents = () => {
    const [subscribers, setsubscribers] = useState([]);
    const [emailList, setEmailList] = useState();
    useEffect(() => {
        const database = getDatabase(firebaseApp);
        // const subscribersRef = ref(database, 'EVENTS/SUBSCRIBERS');
        const unsubscribed=[];
        const unsubscribersRef = ref(database, 'EVENTS/UN_SUBSCRIBERS');
        get(unsubscribersRef).then((snapshot) => {
            snapshot.forEach((snap) => {
                unsubscribed.push(snap.val());
            });
        });
       
        const subscribersRef = query(ref(database, 'EVENTS/SUBSCRIBERS'), orderByChild('time'));
        get(subscribersRef).then((snapshot) => {
            var newMessages = [];
            var emailList = [];
            if (snapshot.exists()) {
                console.log(snapshot.val());
                snapshot.forEach((snap) => {
                    if(unsubscribed.filter((i)=>i.email==snap.val().email).length==0){
                        newMessages.push(snap.val());
                        emailList.push(snap.val().email)
                    }
                });
                console.log("newMessages", newMessages);
                setsubscribers(newMessages.reverse());
                setEmailList(emailList.join(","))
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

        // onValue(subscribersRef, (snapshot) => {
        //     var newMessages = [];
        //     var emailList = [];
        //     snapshot.forEach((snap) => {
        //         newMessages.push(snap.val());
        //         emailList.push(snap.val().email)
        //     });
        //     console.log("newMessages", newMessages);
        //     setsubscribers(newMessages.reverse());
        //     setEmailList(emailList.join(","))
        // });

    }, []);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(emailList);
        alert(subscribers.length + " emails copied to clipboard!");
    }
    return <div className='main-content'>
        <div className='main-header'>
            <h1 className='noPadding main-heading'><span className='title-thin'>Admin</span> Panel </h1>
            <div className='main-header-right'>
                <div>ABSK ADMIN</div>
                <div className='profile-small'></div>
            </div>
        </div>
        <div className='main-content-inner' style={{ justifyContent: "right", paddingRight: 112 }}>
            <div style={{ textAlign: "right" }}>
                {subscribers.length} total subscribers <a onClick={copyToClipboard}><FiLink /></a>
            </div>
        </div>

        <div className='main-content-inner messages-parent'>
            <div className="messages-list">
                {subscribers?.map((item, index) => {
                    // console.log("item", item);
                    return <div className="message-wrap">
                        <div className="message" key={index}>
                            <div>{item.name}</div>
                            <div>{item.email}</div>
                            <div className="time">{timeStampReadable(item.time)}</div>
                        </div>
                        {/* <div className="thread">
                            {mess.map((data) => {
                                return <div>
                                    <div className="mess-time">{timeStampReadableWithTime(data.sent)}</div>
                                    <div>{data.message}</div>
                                </div>
                            })}
                        </div> */}
                    </div>
                })}
            </div>

        </div>

    </div>
}
export default SubrouteEvents;
